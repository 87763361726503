.notification-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.notification-modal {
  position: relative;
  background: #fff;
  border-radius: 8px;
  max-width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  font-size: 16px;
  color: #999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.close-modal:hover {
  color: #666;
}

.modal-body {
  padding: 16px;
}

.notifications-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.empty-notifications {
  text-align: center;
  color: #888;
  font-size: 16px;
}

.notification-item {
  padding: 12px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-item p {
  margin: 0;
  color: #333;
}

.notification-item button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.notification-item button:hover {
  text-decoration: underline;
}

.custom-survey-modal {
  z-index: 1999;
  margin: 15% auto;
  padding: 0% 2% 1% 2%;
  background-color: #F7F7F7;
  transition: opacity 0.3s ease;
  box-shadow: 1px 5px 10px 8px #BBBBBB;
  animation: popup 0.3s ease-in-out;
  width: 350px;
  height: 65vh;
  max-height: 65vh;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 250px) and (max-width: 768px) {
  .custom-survey-modal {
    width: 320px;
    height: 90vh;
    max-height: 90vh;
    overflow: hidden;
    overflow-y: scroll;
  }
}

.capture-buttons {
  display: flex;
  flex-direction: row;
}

.capture-button {
  flex: 1;
}

.inputContainer input[type='date'] {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-size: 12px;
  width: 100%;
  margin: 0%;
}

/* .modal {
  display: block; 
  width: 30%; 
  height: auto; 
  transition: all 0.3s ease; 
}

.modal-content {
  padding: 5vh;
  border-radius: 20px;
  overflow-y: auto; 
}


@media screen and (min-width: 250px) and (max-width: 768px){ 
  .modal {
    width: 80vw;
    height: 100vh; 
    top: 0; 
    left: 0; 
    position: fixed; 
    z-index: 1000; 
  }

  .modal-content {
    height: 90vh; 
    border-radius: 0; 
  }

  .close {
    font-size: 30px; 
    margin-right: 2vh; 
  }

} */