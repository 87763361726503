* {
  font-family: "Lato";
}

.custom-sidebar {
  display: none;
  width: 350px !important;
  height: 99vh;
  z-index: 1000;
  position: absolute;
  top: 0px;
  left: 0px;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  margin: 5px;
  padding: 1%;
  background-color: #161A30;
  opacity: 1;

}

.sidebar-content {
  padding: 1px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 200px;
  /* border: 2px solid white; */
  margin: 0%;
}

.weather-data {
  position: fixed;
  margin: -8.5% -5% 5% -1.5%;
  width: 100vw;
  height: 99.99vh;
  z-index: 1000;
  box-shadow: 1px 5px 86px 8px #141414;
  animation: popup 0.3s ease-in-out;
}

@keyframes modalAnimation {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-content {
  animation: modalAnimation 0.3s ease-in-out;
}

.zcustom {
  z-index: 1000;
}

.info-btn {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  margin-top: 3px;
  background-color: white;
  border: 3px solid #27699e;
  color: #276e9e;
}

.info-btn:hover {
  background-color: #276e9e;
  border: 3px solid #27699e;
  color: white;
}

.chatbot-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 1000;
  border-style: none;
}

@keyframes shakeAnimation {
  0% {
    transform: translateX(-2px);
  }

  25% {
    transform: translateX(2px);
  }

  50% {
    transform: translateX(-2px);
  }

  75% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shakeAnimation 0.5s ease;
}

.chatbot-prompt {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: bold;
  width: 130px;
  padding: 3px;
  border-radius: 5px;
  display: inline-block;
}

.info-container {
  background-color:  #3c3b3b;
  color: rgb(255, 255, 255) !important;
  font-weight: bold;
  position: fixed;
  top: 40%;
  left: 350px;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 90vw;
  height: auto;
  max-height: 90vh;
  z-index: 1000;
  box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.4);
  animation: popup 0.3s ease-in-out;
}

@keyframes popup {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.info-div {
  color: white;
  background-color: #3C3B3B;
  padding: 1%;
  position: fixed;
  top: 40%;
  left: 580px;
  transform: translate(-50%, -50%);
  width: auto;
  width: 500px;
  max-height: 90vh;
  overflow: visible;
  z-index: 1001;
  text-align: justify;
  border-radius: 10px;
  box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.4);
}

@media screen and (max-height: 600px) {
  .info-div {
    margin-top: 5vh;
  }
}

/* palette */
.custom-selection {
  position: relative;
  display: inline-block;
}

.custom-selection select {
  display: none;
}

.select-styled {
  background-color: #FF0000;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  display: inline-block;
}

.select-styled:after {
  content: '';
  border: 6px solid transparent;
  border-top-color: #333;
  position: absolute;
  right: 10px;
  top: calc(50% - 3px);
  pointer-events: none;
}

.select-styled:hover {
  background-color: #ccc;
}

.select-options {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
}

.select-options div {
  padding: 5px;
  cursor: pointer;
}

.select-options div:hover {
  background-color: #F3F3F3;
}

/*Fosr Tabs */
.tab-buttons {
  margin-right: -50px;
  font-size: 12px;
  /* border: 2px solid white; */
}

.tab-input:checked+.tab-buttons {
  background-color: #36383f;
  /* border: 2px solid white; */

}

::-webkit-scrollbar {
  width: 5px;

}

::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 100px;

}

.hiddenActionCont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 1%;
  height: 100%;
  width: 100%;
  max-height: calc(100vh-200px) !important;
  /* overflow: hidden; */
  overflow-y: scroll;
  /* background-color: #363c44; */
  /* border: 2px solid white; */
}

.baseline {
  margin-bottom: 0px;
}

.container {
  display: flex;
  /* background-color: #363c44;  */
  background-color: transparent;
  /* overflow: hidden; */
  /* overflow-y: scroll; */
  padding: 2px 0px;
  border-radius: 1%;
  max-height: calc(100vh - 144px);
  width: 100%;
  justify-content: left;
  align-items: left;
  /* border: 2px solid green; */
  overflow-x: scroll !important;
}

/* For Select Action */
.select {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 97%;
  height: 40px;
  /* border: 2px solid rgb(128, 0, 60); */
}

.option {
  padding: 0 30px 0px 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  background: #778DA9;
  border-top: #222 solid 1px;
  position: absolute;
  top: 0;
  width: 100% !important;
  pointer-events: none;
  order: 2;
  z-index: 1;
  transition: .4s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;

}

.option:hover {
  background: #99b0ce;
}

.select:focus .option {
  position: relative;
  pointer-events: all;
}

.selectopt {
  opacity: 0;
  position: absolute;
  left: -99999px;
}

.selectopt:checked+label {
  order: 1;
  z-index: 2;
  background: #778DA9;
  border-top: none;
  position: relative;
}

.selectopt:checked+label:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  position: absolute;
  right: 10px;
  top: calc(50% - 2.5px);
  pointer-events: none;
  z-index: 3;
}

.selectopt:checked+label:before {
  position: absolute;
  right: 0;
  height: 40px;
  width: 40px;
  content: '';
  background: #778DA9;
}

.defaultLabel {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  opacity: 1;
}


/* color pallete */
/* .Green {
  background: linear-gradient(to right, #00FF00, #7CFC00, #32CD32, #008000, #228B22, #00FF00, #7CFC00, #32CD32);
}

 {
  background: linear-gradient(to right, #FF0000, #DC143C, #FF1493, #B22222, #CD5C5C, #FF0000, #DC143C, #FF1493);
}

.Blue {
  background: linear-gradient(to right, #0000FF, #00BFFF, #87CEEB, #ADD8E6, #4682B4, #0000FF, #00BFFF, #87CEEB);
}

.Violet {
  background: linear-gradient(to right, #9400D3, #8A2BE2, #800080, #7B68EE, #6A5ACD, #9400D3, #8A2BE2, #800080);
}

.Indigo {
  background: linear-gradient(to right, #4B0082, #483D8B, #4169E1, #6A5ACD, #7B68EE, #4B0082, #483D8B, #4169E1);
}

.Orange {
  background: linear-gradient(to right, #FF7F00, #FF8C00, #FFA07A, #FFDAB9, #FF6347, #FF7F00, #FF8C00, #FFA07A);
}

.Yellow {
  background: linear-gradient(to right, #FFFF00, #FFD700, #FFA500, #FFB6C1, #FF4500, #FFFF00, #FFD700, #FFA500);
} */




/* For Segment */

/* .seg-mark {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
} */



.marker-label {
  background-color: gray;
  color: white;
  font-size: 13px;
  width: 100%;
  padding: 10px 20px ;
  height: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;
}

/* .segment-button {
  width: 100%;
  padding: 10px;
  background-color: #B6EADA;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.segment-button:hover {
  background-color: #333;
} */

/* For MAXAR */
.select-container {
  width: 100%;
  margin-top: 0px;
  margin-left: 0px;
}

.custom-select {
  width: 100%;
  font-size: 13px;
  text-align: center;
  color: black;
  text-align: left;
  margin-top: 5px;
  height: fit-content;
  /* background-color: #3e5168;  */
  /* border:  2px solid rgb(190, 238, 59); */
}

/* .visualize-button {
  position: relative;
  margin-top: 20px;
  background-color: rgb(50, 102, 161);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.visualize-button:hover {
  background-color: #333;
} */


/* .loading-earth {
  width: 30px;
  height: 30px;
  z-index: 1000;
  perspective: 100px; 
}

.fa-globe {
  display: inline-block;
  font-size: 24px; 
  color: rgb(255, 255, meet.google.com/dkn-ijuc-dxy255); 
  transform-style: preserve-3d;
  animation: rotateGlobe 5s linear infinite;
} */

/* @keyframes rotateGlobe {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
} */

/* .lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 1000;
  position: absolute;
  top: 11%;
  left: 80%;
  animation: lds-dual-ring 1.2s linear infinite;

} 

 .lds-dual-ring:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring-black {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.lds-dual-ring-black:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid black;
  border-color: transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    color: rgb(61, 61, 61);
    backgroundColor: #d4b60d
  }
}  */

.lds-dual-ring {
  display: inline-block;
  width: 34px;
  height: 34px;
  z-index: 1000;
  position: absolute;
  top: 5%;
  left: 20%;
  perspective: 100px;
}

.lds-dual-ring:before {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 3px solid #4fa714;
  border-color: #50bcfa transparent #808580 transparent;
  animation: lds-dual-ring-rotate 1.2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.lds-dual-ring .fa-globe {
  font-size: 20px;
  background: linear-gradient(to top, rgb(90, 184, 238), #808580);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotateGlobe 5s linear infinite;
}

/* .lds-dual-ring:before {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 3px solid #4fa714;
  border-color: #32a4d8 transparent #64c961 transparent;
  animation: lds-dual-ring-rotate 1.2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.lds-dual-ring .fa-globe {
  font-size: 20px; 
  background: linear-gradient(to top, rgb(90, 184, 238), rgb(64, 235, 141));
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotateGlobe 5s linear infinite;
} */

@keyframes lds-dual-ring-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateGlobe {
  0% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
}

.gradient1 {
  background-image: linear-gradient(to right, #5E5AF2, #EBF3E9, #00441b);
}

.gradient2 {
  background-image: linear-gradient(to right, #FFFFFF, #4147B4, #010AB7);
}

.gradient3 {
  background-image: linear-gradient(to right, #E00303, #FFFFFF, #2A33E0);
}

.gradient4 {
  background-image: linear-gradient(to right, #661C1C, #C9CA69, #073400);
}

.gradient5 {
  background-image: linear-gradient(to right, #84420E, #DDDECC, #001D80);
}

.gradient6 {
  background-image: linear-gradient(to right, #C30D0D, #EEEEAF, #3C8612);
}

.gradient7 {
  background-image: linear-gradient(to right, #0027A9, #EEEEAF, #D81414);
}


.btn-visualize {
  background-color: rgb(50, 102, 161);
  border: 2px solid rgb(50, 102, 161);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 20px;
}

.btn-visualize:hover {
  background-color: transparent;
  border: 2px solid rgb(50, 102, 161);
}

@media screen and (min-width: 450px) and (max-width: 1370px) {
  .custom-sidebar {
    width: 330px !important;
    margin: 5px;
    height: calc(100vh-100px);
    padding: 1%;
  }

  .survey-form {
    width: 300px !important;
    height: 99%;
  }


}

.baseline-cont {
  margin-bottom: 0px;
}

@media screen and (min-width: 150px) and (max-width: 470px) {
  .chatbot-container {
    max-width: '130px';
  }
}



/* @media(max-width:1370px){
    .container{
      max-width: 70%;
      padding: 0%;
    }
  }  */
/* @media(max-height:700px){
    .container{
      max-height: 500px;
    }
  }  */

/* Swipping tool dropdown*/
/* .logo{
  content: "vgtlogo.png" !important;
  width: 100% !important;
  height: 100% !important;
  margin: -20px 0px 10px 5px !important;
  flex:9 !important;
  border:none !important;
 } */

/* 
 img, svg {
  width: 100%;
  vertical-align: middle;
} */


.custom-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  z-index: 5000;
}

/* Hide default HTML checkbox */
.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
  z-index: 5000;
}

/* The slider */
.custom-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 5000;
}

.custom-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 5000;
}

.custom-slider.on {
  background-color: #2196F3;
  z-index: 5000;
}

.custom-slider.on:hover {
  box-shadow: 0 0 1px #2196F3;
  z-index: 5000;
}

.custom-slider.on:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
  z-index: 5000;
}

/* Rounded sliders */
.custom-slider.round {
  border-radius: 17px;
  z-index: 5000;
}

.custom-slider.round:before {
  border-radius: 50%;
  z-index: 5000;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  /* Updated background color */
  color: white;
  padding: 10px 15px;
  border: none;
  height: 40px;
  /* Remove quotes */
  width: 40px;
  /* Remove quotes */
  z-index: 900;
  /* Remove quotes */
  position: absolute;
  /* Remove quotes */
  top: 10px;
  /* Remove quotes */
  left: 10px;
  /* Remove quotes */
  border-radius: 100%;
  /* Rounded button */
  display: flex;
  align-items: center;
  justify-content: center;
}

.openbtn:hover {
  background-color: #444;
}

.hide-show-container {
  display: flex;
  flex-direction: column;
  background-color: #3c3b3b;
  box-shadow: 2px 4px 5px 2px #000000;
  margin: 1% 3%;
  border: none;
  color: white;
  font-size: 13px;
  /* border: 2px solid rgb(255, 251, 6); */
}

.sidepanel {
  width: 350px;
  position: fixed;
  z-index: 1;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #232323;
  overflow-x: hidden;
  overflow-y: hidden;

}

.sidepanel .arrow-logo-container {
  display: flex;
  flex-direction: row;
  margin: 1%;
  padding: 1%;
  gap: 15px;
  justify-content: safe;
}

.sidepanel .arrow-logo-container .closebtn {
  color: white;
  width: 30px;
  /* Increased size for better touch targets */
  height: 30px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  /* Slightly larger font for better visibility */
  background-image: radial-gradient(circle, #002153, #004ab9);
  position: absolute;
  /* Positioning it absolutely */
  top: 10px;
  /* Added top margin for spacing */
  left: 10px;
  /* Added left margin for spacing */
  display: flex;
  /* Flexbox for centering the icon */
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth transitions for hover effects */

}

.sidepanel .arrow-logo-container .closebtn:hover {
  background-color: rgba(0, 74, 185, 0.8);
  /* Change color on hover for feedback */
  transform: scale(1.1);
  /* Slightly scale up on hover */
}

.sidepanel .arrow-logo-container .sidepanel-top-logo {
  width: 80%;
}

.sidepanel .sidepanel-container,
.sidepanel .sidepanel-container input[type="date"] {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #3c3b3b;
  padding: 2%;
  margin: 1% 3%;
  border: none;
  color: white;
}

.sidepanel .calender-cloud-container input[type="date"] {
  padding: 3%;
}

.sidepanel-container .cloud-filter {
  margin-left: 30px;
}

.sidenav {
  flex-direction: column;
  padding: 0.5%;
  margin: 1%;
}

.sidenav .dropdown-btn {
  text-decoration: none;
  font-size: 13px;
  color: #ffffff;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border: none;
}

.sidenav .dropdown-container {
  background-color: #262626;
  padding-left: 8px;
  font-size: 15px;
}

.sidenav .fa-caret-down {
  float: right;
  padding: 4px 0px 4px 25px;
}

.sidenav .fa-caret-up {
  float: right;
  padding: 4px 0px 4px 25px;
}

.sidepanel-container .info-button {
  color: #004ab9;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 11px;
  background-color: white;
  margin-left: 100px;
}

/* globe Loader */
.arrow-logo-container .loading-globe {
  position: absolute;
  top:0;
  right: 50px;
  
}

.lds-dual-ring {
  display: inline-block;
  width: 34px;
  height: 34px;
  z-index: 1000;
  position: absolute;
  top: 0%;
  left: 2%;
  perspective: 100px;
}

.lds-dual-ring:before {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 3px solid #4fa714;
  border-color: #0056fc transparent #53acff transparent;
  animation: lds-dual-ring-rotate 1.2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.lds-dual-ring .fa-globe {
  font-size: 20px;
  background: linear-gradient(to top, #0056fc, #53acff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotateGlobe 5s linear infinite;
}

@keyframes lds-dual-ring-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateGlobe {
  0% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
}

/* additional options */
.hide-show-container .sidepanel-container {
  margin: 0%;
  box-shadow: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  font-size: 13px;
  gap: 30px;
  /* border: 2px solid rgb(243, 0, 0); */
}

.sidepanel-container {
  font-size: 13px;
}

/* .hidden-container {
  display: flex;
  flex-direction: column;
  padding: 4px 15px;
  border: 2px solid rgb(69, 243, 0); 
} */

.opts-container {
  display: flex;
  flex-direction: row;
  margin: 0%;
}

.opts-container div {
  flex: 5;
}

/* .dropdown-btn {
  border: none;
  border-bottom: 2px solid white;
  font-size: 10px;
}
 .dropdown-btn .fa-caret-down {
  padding: 4px 0px 4px 15px;
}
 .dropdown-btn .fa-caret-up {
  padding: 4px 0px 4px 15px;
} */

.visualize-btn {
  /* background-image: linear-gradient(to bottom, #000000, #3c3b3b); */
  background-color: #004ab9;
  border: none;
  padding: 2% 5%;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.visualize-btn:hover {
  /* background-image: linear-gradient(to bottom, #000000, #3c3b3b); */
  background-color: #013c94;
  border: none;
  padding: 2% 5%;
  font-size: 14px;

}

.visualize-btn.shifted-up {
  bottom: 50px; 
}

.dropdown-div {
  position: relative;
  cursor: pointer;
  margin-left: 5px;
  padding: 5px 5px 0px 10px;
}

/* Change color and make the div pop on hover */
.dropdown-div:hover {
  background-color: #f0f0f0;
  color: #000000;
}

#cloud-date-flex-container {
  gap: 10px;
}

.cloud-filter {
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  margin-bottom: 15px;
  /* Space between different filters */
  border: 2px solid green;
}

.filter-checkbox {
  display: flex;
  align-items: left;
  /* border: 2px solid rgb(0, 81, 128); */
}

div.sidepanel-container .filter-checkbox {
  margin-left: 1%;
  /* border: 2px solid rgb(255, 136, 0); */
}

.cloud-slider {
  display: flex;
  align-items: center;
  /* Align slider and label vertically cent */
  margin-top: 10px;
  /* Space between checkbox and slider */
}

label {
  margin-left: 5px;
}

.scroll-sidebar-div:not(.vis-button-div) {
  max-height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;

}

.date-div {
  display: flex;
  flex-wrap: nowrap;
  /* Default to flexbox */
}

.column-aligned {
  flex-direction: column;
  /* Align items in a column */
}

.row-aligned {
  flex-direction: row;
  /* Align items in a row */
  align-items: center;
  /* Center align items vertically */
}

.row-aligned .sidepanel-container {
  max-width: 50%;
  overflow-x: hidden;
}

.addiotnalopts-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 0px 5px 5px;
  /* border: 2px solid white; */
}

/* Mobile Devices: 320px – 480px */
@media screen and (min-width: 320px) and (max-width: 480px) {
  .sidepanel {
    width: 350px;
    /* Example for mobile, adjust as needed */
    position: fixed;
    z-index: 1;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #232323;
    overflow-x: hidden;
    overflow-y: auto;
    /* Allow vertical scrolling */
  }

  div.sidepanel-container {
    padding: 0;
    margin: 0;
  }

  .visualize-btn {
    bottom: 10px;
    /* Adjust button position */
    left: 3;
  }
}

/* Tablets/iPads: 480px – 768px */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .sidepanel {
    width: 350px;
    position: fixed;
    z-index: 1;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #232323;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .visualize-btn {
    bottom: 10px;
    left: 3;
  }

  .info-div {
    color: white;
    background-color: #3c3b3b;
    padding: 1%;
    position: fixed;
    top: 45%;
    left: 500px;
    transform: translate(-50%, -50%);
    width: 350px;
    max-height: 90vh; 
    overflow: hidden;
    overflow-y: scroll;
    z-index: 1001;
    text-align: justify;
    box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.4);
  }
  
}

/* Laptops/Small Screens: 768px – 1024px */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sidepanel {
    width: 350px !important;
    position: fixed;
    z-index: 1;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #232323;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  div.sidepanel-container {
    padding: 0;
    margin: 0;
  }

  .visualize-btn {
    bottom: 0;
    left: 3;
  }
}

/* Desktops/Large Screens: 1024px – 1200px */
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .sidepanel {
    width: 350px;
    position: fixed;
    z-index: 1;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #232323;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .visualize-btn {
    bottom: 0;
    left: 3;
  }
}

/* Extra-large Devices: 1200px and above */
@media screen and (min-width: 1200px) {
  .sidepanel {
    width: 350px;
    position: fixed;
    z-index: 1;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #232323;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .visualize-btn {
    bottom: 0;
    left: 3;
  }
}

.image-results {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  justify-content: justify;
}

.image-item {
  border-radius: 10px;
  width: 100% !important;
  margin: 0px;
  padding: 0px 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.image-info {
  border: 1px solid #808080;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 2%;
}

.image-item:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.image-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #ffffff;
}

.preview-btn {
  background-color: #004ab9;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  margin: 5px 5px 0px 0px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.preview-btn:hover {
  background-color: #0056b3;
}


@media screen and (max-width: 768px) {
  .image-item {
    width: 90%;
  }
}

@media screen and (min-width: 769px) {
  .image-item {
    width: 300px;
  }
}

.popup {
  position: absolute;
  top: 40%;
  left: 350px;
  transform: translate(-50%, -50%);
  max-width: 350px;
  max-height: 50%;
  overflow-y: auto;
  background: #3c3b3b;
  color: white;
  border-radius: 8px;
  box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.4);
  z-index: 1000;
}


.image-results {
  padding: 10px;
}