.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px; /* Body font size */
}

.custom-table th,
.custom-table td {
  padding: 12px; /* Increased padding for better readability */
  text-align: left;
}

.custom-table th {
  background-color: #2c3e50; /* Dark Blue */
  color: white;
  text-align: center;
  font-size: 16px; /* Header font size */
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f8f8f8; /* Light Gray */
}

.custom-table tbody tr:hover {
  background-color: rgb(231, 231, 231); /* Lighter Gray on hover */
}

.btn-delete {
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 14px;
}

/* Date Filter Styles */
label {
  font-weight: normal;
  color: #2c3e50; /* Blue */
  font-size: small;
}

input[type='date'] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 14px;
}

button {
  background-color: #2c3e50; /* Blue */
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}

button:hover {
  background-color: #2d435a; /* Darker Blue on hover */
}


.pagination {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #2c3e50;
  background-color: #fff;
  cursor: pointer;
  border-radius: 3px;
  color: black;
}

.pagination button.active {
  background-color: #2c3e50;
  color: #fff;
}

.pagination button:hover {
  background-color: #2c3e50;
  color: #fff;
}

.pagination button:disabled {
  background-color: #ccc;
  color: #000;
  cursor: not-allowed;
}

.pagination button:not(:disabled):hover {
  background-color: #fff;
  color: black;
}
.pagination .current-page {
  background-color: #2c3e50;
  color: #fff;
}