.table-container {
  margin: 3% 5%;
}

table {
  width: 100%;
  color: #000000;
  background-color: #f7f7f7;
  border: none;
  table-layout: fixed;
  box-shadow: 0 4px 8px 0 #bababa;
}

th,
td {
  text-align: justify;
  padding: 13px;
  font-size: 12px;
  word-wrap: break-word;
  /* border: 2px solid black; */
  /* border-bottom: 0.2px solid rgb(207, 207, 207); */
}

.th {
  padding-left: 4%;
  width: 35%;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  text-align: left;
  align-items: left;
  justify-content: left;
}

th {
  text-align: center;
  align-items: center;
  font-size: 14px;
  justify-content: center;
}

tbody tr:nth-child(even) td {
  background-color: transparent;
  cursor: default;
  color: #000000;
  align-items: center;
  text-align: center;
  align-items: center;
  justify-content: center;
}

tbody tr:nth-child(odd) td {
  background-color: transparent;
  cursor: default;
  color: #000000;
  text-align: center;
  align-items: center;
  justify-content: center;
}

button {
  background-color: rgb(43, 83, 128);
  padding: 5px 10px;
  margin: 2px;
  color: rgb(252, 252, 252);
  border: none;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;
}

button:hover {
  background-color: rgb(31, 61, 95);
  color: #f0f0f0;
}

.btn-update {
  background-color: #4caf50;
  color: #f0f0f0;
}

.btn-update:hover {
  background-color: #327935;
  color: #f0f0f0;
}

.btn-delete {
  background-color: #e92121;
  color: #f0f0f0;
}

.btn-delete:hover,
.cancel-role:hover {
  background-color: #c21e1e;
  color: #f0f0f0;
}

.btn-add,
.cancel {
  background-color: rgb(111, 166, 228);
  background-color: rgb(43, 83, 128);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 20px;
}

.user-modal-header {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.cancel {
  padding: 6px 8px;
  background: #BB2525;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  margin: 3% 0% -1%;
  border-radius: 5px;
}

.cancel:hover {
  background-color: #A42020;
  color: #FFFFFF;
}


.modal-footer {
  display: flex;
  padding: 0%;
}

.modal-footer .btn-add {
  width: 100%;
  margin: 0%;
}

.form-control {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-size: 12px;
}

.permissions {
  color: white;
  padding: 6px 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  background-color: rgb(43, 83, 128);
  border: 1px solid rgb(43, 83, 128);
  color: rgb(255, 255, 255);

}

.permissions:hover {
  background-color: rgb(31, 60, 94);
  border: 1px solid rgb(43, 83, 128);
  color: rgb(255, 255, 255);

}

.active-dot {
  color: #7ed310;
  font-size: 17px;
  border-radius: 50%;
  background-color: #ffffff;
  display: inline-block;
}

.inactive-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
}


.custom-modal {
  margin: 8% auto;
  padding: 0% 2% 1% 2%;
  background-color: #F7F7F7;
  transition: opacity 0.3s ease;
  box-shadow: 1px 5px 10px 8px #BBBBBB;
  animation: popup 0.3s ease-in-out;
  width: fit-content;
  max-height: calc(100vh-400px);
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 5px;
  /* border: 2px solid red; */
}

.custom-modal-cproject{
  margin: 8% auto;
  padding:0% 2% 1% 2%;
  background-color: #F7F7F7;
  transition: opacity 0.3s ease;
  box-shadow: 1px 5px 10px 8px #BBBBBB;
  animation: popup 0.3s ease-in-out;
  width: 450px;
  max-height: calc(100vh-400px);
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 5px;
}

.custom-modal-chatbot {
  padding: auto;
  margin: 8% auto;
  padding: 2% 0% 1% 3%;
  width: 800px;
  background-color: #F7F7F7;
  transition: opacity 0.3s ease;
  box-shadow: 1px 5px 10px 8px #BBBBBB;
  animation: popup 0.3s ease-in-out;
}

@keyframes popup {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  table {
    font-size: 10px;
  }

  input {
    font-size: 10px;
  }

  .custom-modal {
    margin: 5% auto;
  }
}


/* Amit Edited css Surve form */


.data-input-container {
  padding: 20px;
}

.data-input-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.data-input-row label {
  margin-right: 10px;
}

.data-input-row input[type="text"],
.data-input-row input[type="number"],
.data-input-row input[type="date"],
.data-input-row input[type="time"],
.data-input-row input[type="location"],
.data-input-row input[type="media"],
.data-input-row select {
  margin-right: 10px;
  padding: 5px;
  font-size: 14px;
}

.data-input-row select {
  margin-left: auto;
  /* Push the dropdown to the end */
}

/* button {
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
} */


.custom-chatbot-table {
  border-collapse: collapse;
  max-height: 200px;
  overflow: scroll;
  box-shadow: none;
  max-width: 600;
}

.custom-chatbot-table-container {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: auto;
  display: block;
  padding: 8px 0px;
  font-size: 12px;
}

.custom-chatbot-table th,
.custom-chatbot-table td {
  padding: 8px 0px;
  font-size: 12px;
}

.custom-chatbot-table th {
  background-color: rgb(78, 78, 78);
  color: rgb(255, 255, 255);
  width: 100px;
  padding: 8px 10px;

}

.custom-chatbot-table tbody tr:nth-child(even) td {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.custom-chatbot-table tbody tr:nth-child(odd) td {
  background-color: rgb(235, 235, 235);
  color: rgb(0, 0, 0);
}

.custom-chatbot-table-container::-webkit-scrollbar {
  height: 5px !important;
}

.custom-chatbot-table-container::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
}

.watermark {
  position: absolute;
  bottom: 0px;
  right: 5px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.form-check-input:checked {
  background-color: blue;
  border-color: white;
}

.form-check-input {
  background-color: white;
  border-color: white;
}


/* for toggle button  */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0 4px 8px 0 #2c2c2c;
}

input:checked + .slider {
  background-color: #2c3e50;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2c3e50;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}