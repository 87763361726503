.admin-container {
  background-color: #f3f3f3;
  color: white; 
  display: flex;
  flex-direction: row;
  max-height: 100vh;
  font-family: "Lato";
}

.left-section {
  background-color: #2c3e50;
  flex: 3;
  display: flex;
  flex-direction: column;
  padding-top: 0.5%;
}

.left-section .profile {
  display: flex;
  flex-direction: row;
  padding: 2%;
}

.left-section .profile .profile-img {
  flex: 2;
  text-align: center;
}

.left-section .profile .profile-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.left-section .profile .profile-info {
  flex: 4;
  padding: 1% 5%;
  font-size: 15px;
  font-family: "Lato";
  text-align: right;
}

.left-section .profile .profile-info .username {
  font-weight: bold;
}

.left-section .profile .profile-info .logintime {
  font-weight: normal;
}

.left-section .menus {
  height: 100vh;
  overflow-y: auto;
  padding-top: 5%;
  font-size: 15px;
  font-family: "Lato";
}

.left-section .menus::-webkit-scrollbar {
  width: 5px;
}

.left-section .menus {
  scrollbar-width: thin;
  scrollbar-color: #585858 #3c3b3b;
}

.left-section .menus ul {
  list-style-type: none;
  padding: 1%;
}

.left-section .menus ul li {
  margin: 0% 5%;
  padding: 4% 2%;
}

.left-section .menus ul li:hover {
  background-image: linear-gradient(to bottom, #5a7da0, #2c3e50);
  border-right: 5px solid #0075ff;
}

.menus ul .li.active {
  background-image: linear-gradient(to bottom, #5a7da0, #2c3e50);
  border-right: 5px solid #0075ff;
}

.space-used {
  bottom: 0;
  padding: 1% 3%;
}

.right-section {
  flex: 10;
}

.right-section .toolbar {
  padding: 1%;
  display: flex;
  flex-direction: row;
}

/* For Profile & For Organization */
.right-section .toolbar .active-tab {
  flex: 3;
  font-weight: bold;
  font-size: 20px;
  color: #2c3e50;
  padding: 0% 3%;
  /* background-image: linear-gradient(to bottom, #5a7da0, #ffffff); */
  border-left: 5px solid #2c3e50;
}

.right-section .toolbar .admin-tools {
  flex: 9;
  padding: 0.85%;
  text-align: end;
}

.right-section .toolbar .admin-tools i {
  margin-right: 2%;
  font-size: 20px;
  color: #2c3e50;
}

.right-section .tab-container {
  margin-top: 0.5%;
  margin: 1%;
  padding: 1%;
  /* border:2px solid rgb(230, 7, 7);
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden; */
}

.right-section .tab-container .profile-container {
  display: flex;
  flex-direction: row;
}

.right-section .tab-container .profile-container .edit-profile-pic {
  flex: 3;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-family: "Lato";
}

.right-section .tab-container .profile-container .edit-profile-pic img {
  width: 150px;
  height: 150px;
}

.updt-btn {
  background-image: linear-gradient(to bottom, #2c3e50, #2c3e50);
  border: none;
  color: white;
  padding: 4% 10%;
  border-radius: 5px;
  margin-top: 1%;
  width: 200px;
  font-size: 15px;
  font-family: "Lato";
}

.right-section .tab-container .profile-container .edit-profile-info table {
  box-shadow: none;
  background-color: transparent;
}

.right-section .tab-container .profile-container .edit-profile-info {
  flex: 8;
  display: flex;
  flex-direction: column;
  padding: 0% 10%;
  color: #2c3e50;
  font-size: 15px;
  font-family: "Lato";
}

.profile-container .edit-profile-info .th,
.profile-container .edit-profile-info td {
  font-size: 15px;
  font-family: "Lato";
  text-align: left;
}

.all-tab-container {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.all-tab-container::-webkit-scrollbar {
  width: 5px;
}

.all-tab-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  height: 10px;
}

.right-section .tab-container .profile-container .edit-profile-pic .org-logo {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.update {
  padding: 5px 10px;
  color: white;
  font-family: "Lato";
  font-size: 15px;
  background-image: linear-gradient(to bottom, #2c3e50, #2c3e50);
}

.update:hover {
  color: white;
  background-image: linear-gradient(to bottom, #22313f, #233444);
}

.btn-danger {
  font-family: "Lato";
  margin-left: 1%;
  font-size: 15px;
}

.add-btn {
  padding: 8px 16px;
  border: none;
  color: white;
  font-size: 15px;
  font-family: "Lato";
  border-radius: 5px;
  background-image: linear-gradient(to bottom, #2c3e50, #2c3e50);
}

.add-btn:hover {
  color: white;
  font-size: 15px;
  font-family: "Lato";
  border-radius: 5px;
  background-image: linear-gradient(to bottom, #233241, #1d2935);
}

/* User Section */
.search-input {
  width: 250px;
  padding: 0px 2px;
  font-size: 15px;
  font-family: "Lato";
  border: none;
  background-color: transparent;
}

.view-btn {
  padding: 2.5%;
  border: none;
  color: white;
  font-size: 15px;
  font-family: "Lato";
  border-radius: 5px;
  background-image: linear-gradient(to bottom, #2c3e50, #2c3e50);
}

/* projects sections */

.tabs-header {
  position: sticky;
  top: 0;
  font-size: 15px;
  font-family: "Lato";
  padding: 0px;
  background-color: #f3f3f3;
}

.project-tabs {
  background-color: transparent;
  /* border-bottom: 5px solid #000000; */
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  font-family: "Lato";
  border: 2px solid #f3f3f3;
}

.project-tabs.active {
  background-color: #2c3e50;
  /* border: 5px solid #fc0000; */
  color: #ffffff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.project-tabs:not(.active) {
  background-color: transparent;
  color: #2c3e50;
}

.project-tabs:hover {
  background-color: #202e3b;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.thead-row th {
  position: sticky;
  top: 0;
  font-size: 15px;
  font-family: "Lato";
  background-color: transparent;
  color: #2c3e50;
}

input[type="date"] {
  border: 1px solid #ccc;
  padding: 1px 10px;
  font-size: 14px;
  width: 150px;
}

.tbody td {
  font-size: 15px;
  font-family: "Lato";
}

.country-code {
  display: flex;
  flex-direction: row;
}

.datpicker-input {
  width: 100px;
  margin-left: 5%;
  text-align: center;
  font-size: 13px;
}