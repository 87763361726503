.custom-table1 {
  width: 100% !important;
  border-collapse: collapse;
  font-size: 14px;
  /* Body font size */
}

.custom-table1 th,
.custom-table1 td {
  padding: 12px;
  /* Increased padding for better readability */
  text-align: left;
}

.custom-table1 th {
  background-color: #2c3e50;
  /* Dark Blue */
  color: white;
  text-align: left;
  font-size: 16px;
  /* Header font size */
}

.custom-table1 td {
  text-align: start !important;
  font-size: 16px !important;
}

.custom-table1 tbody tr:nth-child(even) {
  background-color: #f8f8f8;
  /* Light Gray */
}

.custom-table1 tbody tr:hover {
  background-color: rgb(231, 231, 231);
  /* Lighter Gray on hover */
}

.btn_view {
  background-color: #fff0;
  color: black;
}

.btn_view:hover {
  background-color: rgb(111, 111, 111);
  color: white;
}

.btn_edit {
  background-color: #fff0;
  color: black;
}

.btn_edit:hover {
  background-color: green;
  color: white;
}

.btn_delete {
  background-color: #fff0;
  color: red;
}

.custom-table1 th,
.custom-table1 td {
  padding: 12px;
  /* Increased padding for better readability */
  text-align: left;
}

.custom-table1 th {
  background-color: #2C3E50;
  /* Dark Blue */
  color: white;
  text-align: left;
  font-size: 16px;
  /* Header font size */
}

.custom-table1 td {
  text-align: start !important;
  font-size: 16px !important;
}

.custom-table1 tbody tr:nth-child(even) {
  background-color: #F8F8F8;
  /* Light Gray */
}

.custom-table1 tbody tr:hover {
  background-color: rgb(231, 231, 231);
  /* Lighter Gray on hover */
}

.btn_view {
  background-color: #fff0;
  color: black;
}

.btn_view:hover {
  background-color: blue;
  color: white;
}

.btn_download {
  background-color: #fff0;
  color: black;
}

.btn_download:hover {
  background-color: black;
  color: white;
}


tabs-container {
  display: flex;
  column-gap: 2vh;
  font-size: 20px;
}

.tab {
  cursor: pointer;
  padding: 28px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 20px;

  transition: background-color 0.3s, color 0.3s;
}

.tab.active {
  background-color: #2c3e50;
  color: white;
  font-size: 22px;
  padding-bottom: 5vh;
  padding-top: 1.7vh;
}

.underline {
  height: 2px;
  background: white;
  width: 100%;
  margin-top: 5px;
}
.li.active {
  background-color: #555; 
}