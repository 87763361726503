.admin-container {
  display: flex;
  max-height: 100vh;
  background-color: #f0f0f0;
}

@media screen and (min-height: 400px) and (max-height: 770px) {
  .admin-container {
    height: 150vh;
  }
}

/* Sidebar styles */
.sidebar {
  background-color: rgb(29, 52, 78);
  color: #fff;
  padding: 20px;
  max-width: 300px;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206);
  border-radius: 100px;

}

.profile {
  display: flex;
  font-weight: bold;
  padding: 5px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
}

.profile-pic img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-name {
  margin: 0px 6px 6px 15px;
}

.profile-name span {
  font-size: 12px;
  font-weight: 100;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul .li {
  cursor: pointer;
  padding: 5%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

ul .li:hover {
  background-color: rgb(62, 82, 104);
  border-right: 3px solid rgb(3, 109, 141);
  padding: 5%;
  transform: translateY(-3px);
  color: #4495ca;
}

hr {
  border: 0;
  border: 1px solid #666;
  margin: 10px 0;
}


/* Main content styles */
.main-page {
  flex-grow: 1;
  background-color: #f0f0f0;
  padding: 20px;
  height: 100vh;
}

.admin-head {
  background-color: #f8f8f8;
  color: rgb(0, 84, 163);
  display: flex;
  padding: 2%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-head h4 {
  margin: 0;
  margin-left: 20px;
}

.btn-logout {
  /* background-color: rgb(43, 83, 128); */
  color: rgb(255, 255, 255);
  padding: 5px 20px;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.btn-logout:hover {
  border-color: rgb(204, 42, 42);
  background-color: rgb(207, 47, 47);
}


.hovered {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.activity {
  margin-bottom: 10px;
  margin-left: 25px;
  padding: 10px;
  border-radius: 5px;
}

.project-changed {
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin-left: 25px;
}

.tab-changed {
  color: #ff5722;
  font-weight: bold;
  margin-left: 25px;
}

.draw,
.layer {
 
  background-color: rgb(255, 255, 255);

}

.draw p,
.layer p {
  margin: 0;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0% 5%;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: rgb(255, 255, 255);
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: -3px;
}

.track-dots::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 4px solid #FF9F55;
  top: 65px;
  border-radius: 50%;
  z-index: 1;
}

.right::after {
  left: -10px;
}

.break {
  margin-top: 1rem; /* or any desired value */
  width: 100%;
  display: block;
}



::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206);;
  border-radius: 100px;

}

.admin-container {
  display: flex;
}

.sidebar {
  background-color: #2c3e50;
  color: white;
  height: 100vh;
}

.profile {
  text-align: center;
  padding: 20px;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}

.profile-name {
  margin-top: 10px;
}

.admin-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ecf0f1;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.btn-notification,
.btn-logout {
  background-color: #3498db;
  border: none;
  color: white;
  padding: 10px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-notification:hover,
.btn-logout:hover {
  background-color: #2980b9;
}

.notification-modal {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 300px;
  max-height: 60vh;
  overflow-y: auto;
  background-color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  text-align: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.notification {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.notification p {
  margin: 0;
  padding: 5px 0;
}

.notification-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.btn-approve,
.btn-reject {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-approve {
  background-color: #2ecc71;
  color: white;
}

.btn-reject {
  background-color: #e74c3c;
  color: white;
}

.btn-approve:hover {
  background-color: #27ae60;
}

.btn-reject:hover {
  background-color: #c0392b;
}

.close-modal {
  background-color: #e74c3c;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.close-modal:hover {
  background-color: #c0392b;
}
